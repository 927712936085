<template>
  <div>
    <v-card elevation="2" outlined shaped tile class="mb-2">
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="2">
            <h4>{{ this.$t("Kilometers") }}</h4>
          </v-col>
          <v-col cols="12" sm="12" md="8">
            <otherstatistics :data="statistics_data1_km" :isPageCat="'KM'" />
          </v-col>
          <v-col cols="12" sm="12" md="2">
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
                class="text-right"
                style="display: flex; align-items: flex-end; justify-content: flex-end;"
              >
                <h5 v-if="isteam === 'my'">
                  username: {{ isAuthenticated && user.username }}
                </h5>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12" class="text-right">
                <v-btn
                  v-if="isteam === 'my' && !isGPV"
                  color="primary"
                  dark
                  @click="$refs.Kilometers.onItemEdit()"
                >
                  {{ $t("newitem") }}
                </v-btn>
                <v-btn
                  color="warning"
                  dark
                  class="ml-2"
                  @click="$refs.Kilometers.downloadExcel()"
                >
                  <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
                  Export
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col cols="12" sm="12" md="12">
            <Kilometers ref="Kilometers" :isteam="isteam" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card elevation="2" outlined shaped tile>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="2">
            <h4>{{ this.$t("Other Expenses") }}</h4>
          </v-col>
          <v-col cols="12" sm="12" md="8">
            <otherstatistics
              :data="statistics_data1_other_expenses"
              :isPageCat="'OtherExpenses'"
            />
          </v-col>
          <v-col cols="12" sm="12" md="2">
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
                class="text-right"
                style="display: flex; align-items: flex-end; justify-content: flex-end;"
              >
                <h5 v-if="isteam === 'my'">
                  username: {{ isAuthenticated && user.username }}
                </h5>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12" class="text-right">
                <v-btn
                  v-if="isteam === 'my'"
                  color="primary"
                  dark
                  @click="$refs.Otherexpenses.onItemEdit()"
                >
                  {{ $t("newitem") }}
                </v-btn>
                <v-btn
                  color="warning"
                  dark
                  class="ml-2"
                  @click="$refs.Otherexpenses.downloadExcel()"
                >
                  <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
                  Export
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col cols="12" sm="12" md="12">
            <Otherexpenses ref="Otherexpenses" :isteam="isteam" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";
import Kilometers from "./kilometers";
import Otherexpenses from "./otherexpenses";
import otherstatistics from "./otherstatistics";

export default {
  name: "approval",
  props: ["isteam"],
  components: {
    Kilometers,
    Otherexpenses,
    otherstatistics
  },
  data() {
    return {};
  },
  watch: {},
  computed: {
    ...mapGetters("auth", ["isAuthenticated", "isSPV", "isGPV", "user"]),
    ...mapGetters("expenses", [
      "statistics_data1_km",
      "statistics_data1_other_expenses"
    ])
  },
  methods: {
    init() {}
  },
  mounted() {
    this.$store.dispatch("auth/getGlobalState");
    this.init();
  }
};
</script>

<style></style>
