<template>
  <div class="status_wrapper">
    <v-row v-if="isPageCat === 'KM'">
      <v-col cols="12" sm="3" md="3">
        <b-card
          bg-variant="dark"
          v-bind:header="$t('pendingapproval')"
          class="text-center"
        >
          <b-card-text>{{
            statistics_data.current_pending_approval_lines
          }}</b-card-text>
        </b-card>
      </v-col>
      <v-col cols="12" sm="3" md="3">
        <b-card
          bg-variant="dark"
          v-bind:header="$t('Incidence')"
          class="text-center"
        >
          <b-card-text>{{
            statistics_data.current_incidence_lines
          }}</b-card-text>
        </b-card>
      </v-col>
      <v-col cols="12" sm="3" md="3">
        <b-card
          bg-variant="dark"
          v-bind:header="$t('total KM of current Month')"
          class="text-center"
        >
          <b-card-text>{{ statistics_data.current_totalKM?.toFixed(2) }}</b-card-text>
        </b-card>
      </v-col>
      <v-col cols="12" sm="3" md="3">
        <b-card
          bg-variant="dark"
          v-bind:header="$t('AVG og total KM of last 6 M')"
          class="text-center"
        >
          <b-card-text>{{ statistics_data.avg_totalKM_last6M?.toFixed(2) }}</b-card-text>
        </b-card>
      </v-col>
    </v-row>
    <v-row v-if="isPageCat === 'OtherExpenses'">
      <v-col cols="12" sm="3" md="3">
        <b-card
          bg-variant="dark"
          v-bind:header="$t('pendingapproval')"
          class="text-center"
        >
          <b-card-text>{{
            statistics_data.current_pending_approval_lines
          }}</b-card-text>
        </b-card>
      </v-col>
      <v-col cols="12" sm="3" md="3">
        <b-card
          bg-variant="dark"
          v-bind:header="$t('Incidence')"
          class="text-center"
        >
          <b-card-text>{{
            statistics_data.current_incidence_lines
          }}</b-card-text>
        </b-card>
      </v-col>
      <v-col cols="12" sm="3" md="3">
        <b-card
          bg-variant="dark"
          v-bind:header="$t('Expenses of current Month')"
          class="text-center"
        >
          <b-card-text>{{
            statistics_data.current_totalAmount &&
              currency_format(statistics_data.current_totalAmount)
          }}</b-card-text>
        </b-card>
      </v-col>
      <v-col cols="12" sm="3" md="3">
        <b-card
          bg-variant="dark"
          v-bind:header="$t('AVG og Expenses of last 6 M')"
          class="text-center"
        >
          <b-card-text>{{
            statistics_data.avg_totalAmount_last6M &&
              currency_format(statistics_data.avg_totalAmount_last6M)
          }}</b-card-text>
        </b-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "otherstatistics",
  props: ["data", "isPageCat"],
  data() {
    return {
      statistics_data: {
        current_pending_approval: 0,
        current_incidence: 0,
        rest_pending_approval: 0,
        rest_incidence: 0,
      },
    };
  },
  watch: {
    async data(newVal, oldVal) {
      console.log(newVal, oldVal);
      if (newVal) {
        this.statistics_data = newVal;
      }
      return newVal;
    },
  },
  computed: {},
  methods: {
    setdata(data) {
      this.statistics_data = data;
    },
    init() {},
  },
  mounted() {
    this.init();
  },
};
</script>

<style>
.status_wrapper {
  text-align: center;
}
.status_wrapper .row {
  margin-top: 0;
  color: #000;
}
.status_wrapper .bg-dark {
  background-color: #9f9f9f !important;
}
</style>
